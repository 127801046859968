import { alertModal } from './alert_modal'

const CommonFunctions = {
  showIEToastError(message = "") {
    var ua = window.navigator.userAgent;
    var msie =/Edge\/|Trident\/|MSIE /.test(ua);

    // Return if not IE browser
    if (!msie) { return; }
    if (!message) {
      // Default message
      message = "<span class='custom-toastr-message'>Internet Explorerは非対応です。</br>Chrome、Edge、Firefox、Safari<br>のいずれかで利用してください。</span>";
      if(window.location.href.indexOf("/company/candidates") >= 0) {
        alertModal('Internet Explorerは非対応です。\n Chrome、Edge、Firefox、Safari\n のいずれかで利用してください。')
      }
    }
    toastr.error(
      message,
      "",
      { timeOut: 0, extendedTimeOut: 0 }
    );
  },
  // #613 warning on IE is only show for company screens.
  showToastErrorWithScreens: function() {
    var currentScreen = window.location.pathname.split("/")[1];
    if (currentScreen == "company") {
      this.showIEToastError();
    }
  }
}

export default CommonFunctions;
