// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import CommonFunctions from './shared/commons'
import SelectSearchable from './shared/select_searchable'

$(document).on('turbolinks:load', function() {
  CommonFunctions.showToastErrorWithScreens();
  SelectSearchable.init();
});

// toastr
import toastr from 'toastr'

window.toastr = toastr

toastr.options = {
  "closeButton": true,
  "positionClass": "toast-bottom-right",
  "timeOut": "3000",
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Stylesheets
import "../stylesheets/application.scss"
